<template>
  <div class="media-table-container">
    <div class="control">
      <div class="left">
        <a-popover
          trigger="click"
          overlayClassName="media_popover"
          placement="bottomLeft"
          @visibleChange="visibleChange"
        >
          <template slot="content">
            <a-checkbox-group class="media_check_group" :value="groupByList" @change="handleChangeChecked">
              <a-checkbox
                v-for="item in dimensionFilterList"
                :key="item.id"
                class="check_box"
                :value="item.key"
                :disabled="item.key === 1"
              >
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </template>
          <a-button type="link"><a-icon type="radar-chart" />维度</a-button>
        </a-popover>
        <template v-for="(item, index) in dimensionFilterList">
          <div class="dimension_item" :key="index" v-if="searchquery.groupByList.includes(item.key)">
            {{ item.label
            }}<a-icon v-if="item.key !== 1" @click="handleCloseItem(item, index)" class="close_icon" type="close" />
          </div>
        </template>
      </div>
      <div class="right">
        <a-button type="link" @click="handleClickCustTagsBtn"><a-icon type="edit" />自定义指标</a-button>
        <a-button :loading="downLoading" type="link" @click="downloadReport"><a-icon type="download" />导出</a-button>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        tableLayout="fixed"
        @change="handleSort"
        :scroll="{ x: 1200 }"
        :loading="isLoading"
        bordered
      >
        <!-- 应用 -->
        <div slot="appName" slot-scope="appName, record">
          <component v-if="appName" style="width:13px;height: 13px" :is="+record.os === 1 ? 'AndroidSvg' : 'IosSvg'"></component>{{ appName }}
        </div>
        <!-- 操作系统 -->
        <div slot="os" slot-scope="os">
          <div>{{ os === 1 ? '安卓' : os === 0 ? 'IOS' : '-' }}</div>
        </div>
        <!-- 流量填充率 -->
        <div slot="requestFilledRate" slot-scope="requestFilledRate">
          <div>{{ requestFilledRate }}%</div>
        </div>
        <!-- 展示率 -->
        <div slot="unitImpressionRate" slot-scope="unitImpressionRate">
          <div>{{ unitImpressionRate }}%</div>
        </div>
        <!-- 点击率 -->
        <div slot="unitClickRate" slot-scope="unitClickRate">
          <div>{{ unitClickRate }}%</div>
        </div>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 1"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
    </div>
    <m-empty style="height: 75vh" v-if="dataList.length === 0">
      <template #description>暂无数据 </template>
    </m-empty>
    <CustTagsModal
      v-if="custTagsVisible"
      :visible="custTagsVisible"
      :targetArr="allTagColumns"
      :sureArr="sureArr"
      :storageName="storageName"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :showTitle="false"
      :hasCheckNum="false"
    />
  </div>
</template>

<script>
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { getMediaReport } from '@/api/reportdatas'
import { mapState } from 'vuex'
import excel from '@/utils/excel'
import request from '@/utils/request'
import { allDimensionColumns, allTagColumns } from './allColumns'
import CustTagsModal from '@/components/common/CustTags'
import { numFormat } from '@/utils/dealNumber'
export default {
  components: { CustTagsModal, IosSvg, AndroidSvg },
  data () {
    return {
      storageName: 'media_0209', // 本地缓存key
      allTagColumns,
      custTagsVisible: false,
      sureArr: [],
      searchquery: {
        sortBy: '',
        sortOrder: '0',
        page: 1,
        limit: 10,
        groupByList: [1]
      },
      dimensionFilterList: [
        {
          label: '日期',
          id: 'date',
          key: 1
        }
      ],
      dimensionName: {
        1: {
          label: '日期',
          id: 'date',
          key: 1
        },
        2: {
          label: '应用',
          id: 'appName',
          key: 2
        },
        3: {
          label: '广告位',
          id: 'placeName',
          key: 3
        },
        4: {
          label: '广告样式',
          id: 'positionName',
          key: 4
        },
        5: {
          label: '广告平台',
          id: 'platName',
          key: 5
        },
        6: {
          label: '系统类型',
          id: 'os',
          key: 6
        }
      },
      groupByList: [1],
      dataList: [],
      total: 0,
      isLoading: false,
      sortInfo: {
        date: false,
        unitRevenue: false,
        mediaUnitRevenue: false,
        ecpm: false
      },
      downLoading: false,
      columns: []
    }
  },
  props: {
    datas: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    datas: {
      handler (val) {
        this.dimensionFilterList = []
        for (const item in this.dimensionName) {
          if (val.includes(this.dimensionName[item].id)) {
            this.dimensionFilterList.push(this.dimensionName[item])
          }
        }
        this.allTagColumns = this.allTagColumns.filter((item) => val.includes(item.prop))
        this.initTargetArr()
        this.getColumns()
      }
    },
    immediate: true
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      audit: (state) => state.user.audit,
      mediaAccess: (state) => state.user.mediaAccess
    })
  },
  methods: {
    // 获取指标列表数组
    initTargetArr () {
      // if (!localStorage.getItem(this.storageName)) {
      // } else {
      //   this.allTagColumns = JSON.parse(localStorage.getItem(this.storageName))
      // }
      this.sureArr = this.allTagColumns.filter((item) => item.checked)
      this.getColumns()
    },
    changeCustTags (targetArr, sureArr) {
      this.allTagColumns = targetArr
      this.sureArr = sureArr
      this.getColumns()
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 关闭单个维度
    handleCloseItem (item, index) {
      this.groupByList = this.groupByList.filter((child) => +child !== +item.key)
      this.searchquery.groupByList = [...this.groupByList]
      this.getColumns()
      this.getTableData()
    },
    // 维度选择
    handleChangeChecked (e) {
      this.groupByList = e
    },
    // 关闭popover后进行维度搜索
    visibleChange (e) {
      if (e === false) {
        this.searchquery.groupByList = [...this.groupByList]
        this.getColumns()
        this.getTableData()
      }
    },
    getColumns () {
      const newColumns = []
      this.datas.forEach((item) => {
        const column = JSON.parse(JSON.stringify(allDimensionColumns)).find((d) => d.dataIndex === item)
        column && newColumns.push(column)
      })
      // 维度
      const groupByColumns = []
      newColumns.forEach((item) => {
        if (this.groupByList.includes(item.key)) {
        // if (this.groupByList.includes(item.key) || this.hasSelected(item)) {
          groupByColumns.push(item)
        }
      })
      // 指标
      this.columns = [...groupByColumns, ...this.sureArr]
      // 广告位维度和广告位选择 操作系统维度和操作系统选择 情况下 DAU 人均收益 不显示
      if (
        this.searchquery.placeIdList.length > 0 ||
        this.searchquery.groupByList.includes(3) ||
        this.searchquery.positionIdList.length > 0 ||
        this.searchquery.groupByList.includes(4)
      ) {
        this.columns = this.columns.filter((item) => ![7, 17].includes(item.key))
      }
      // 广告平台维度和广告平台选择情况下 DAU 流量请求 流量填充 流量填充率 人均收益 不显示
      if (this.searchquery.platIdList.length > 0 || this.searchquery.groupByList.includes(5)) {
        this.columns = this.columns.filter((item) => ![7, 8, 9, 10, 17].includes(item.key))
      }
    },
    hasSelected (item) {
      switch (item.key) {
        case 1:
          return true
        case 2:
          return this.searchquery.appIdList.length > 0
        case 3:
          return this.searchquery.placeIdList.length > 0
        case 4:
          return this.searchquery.positionIdList.length > 0
        case 5:
          return this.searchquery.platIdList.length > 0
        case 6:
          return this.searchquery.osIdList.length > 0
        default:
          return false
      }
    },
    dealquery (query) {
      this.searchquery.sortBy = ''
      this.searchquery.sortOrder = '0'
      this.sortInfo.date = false
      this.sortInfo.unitRevenue = false
      this.sortInfo.mediaUnitRevenue = false
      this.sortInfo.ecpm = false
      this.searchquery.page = 1
      this.searchquery.sortBy = ''
      this.searchquery.sortOrder = ''
      this.searchquery = { ...this.searchquery, ...query }
      this.getTableData()
    },
    async getTableData () {
      this.isLoading = true
      this.getColumns()
      const resp = await getMediaReport(this.searchquery)
      this.total = resp.data ? resp.data.total : 0
      const aggregate = resp.data ? resp.data.aggregate : {}
      aggregate.date = '汇总'
      const items = resp.data ? resp.data.items : []
      this.dataList = [aggregate, ...items]
      this.dataList[0] && (this.dataList[0].os = '-')
      this.dataList.length === 1 && (this.dataList = [])
      this.dataList.map((item) => {
        item.request = numFormat(item.request, 3, '')
        item.unitImpression = numFormat(item.unitImpression, 3, '')
        item.unitClick = numFormat(item.unitClick, 3, '')
        item.mediaUnitRevenue = numFormat(item.mediaUnitRevenue, 3, '')
        item.unitEcpm = numFormat(item.unitEcpm, 3, '')
        item.dau = numFormat(item.dau, 3, '')
        item.unitRevenue = numFormat(item.unitRevenue, 3, '')
        item.response = numFormat(item.response, 3, '')
        item.arpu = numFormat(item.arpu, 3, '')
        // item.unitImpressionRate = numFormat(item.unitImpressionRate, 3, '')
        // item.unitClickRate = numFormat(item.unitClickRate, 3, '')
        // item.requestFilledRate = numFormat(item.requestFilledRate, 3, '')
      })
      this.isLoading = false
    },
    handleSort (pagination, filters, sorter) {
      this.columns.forEach((item) => {
        item.sortOrder = undefined
      })
      this.searchquery.sortBy = sorter.field
      const index = this.columns.findIndex((item) => item.dataIndex === sorter.field)
      this.columns[index].sortOrder = sorter.order
      this.searchquery.sortOrder = sorter.order === 'ascend' ? '0' : '1'
      this.sortInfo.date = false
      this.sortInfo.unitRevenue = false
      this.sortInfo.mediaUnitRevenue = false
      this.sortInfo.ecpm = false
      this.sortInfo[sorter.field] = sorter.order
      this.searchquery.page = 1
      this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    },
    async downloadReport () {
      this.downLoading = true
      const searchquery = JSON.parse(JSON.stringify(this.searchquery))
      searchquery.orderByList = [...this.searchquery.groupByList, ...this.sureArr.map((item) => item.key)]
      const query = JSON.parse(JSON.stringify(searchquery))
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `mediaReport.xlsx`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
      this.downLoading = false
    },
    // 媒体报表数据导出
    exportToExcel (query) {
      return request.post('/report/downloadNewMediaReport', query, {
        responseType: 'blob'
      })
    }
  }
}
</script>

<style lang="less">
.media-table-container {
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  min-height: 75vh;
  .control {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    .left {
      display: flex;
      .dimension_item {
        user-select: none;
        line-height: 28px;
        height: 35px;
        margin: 0 10px;
        background: #eee;
        padding: 3px 10px;
        border-radius: 3px;
        .close_icon {
          color: #000;
          margin: 0 5px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .table {
    .pagination {
      padding: 10px 0;
    }
  }
}
.media_popover {
  .ant-popover-inner-content {
    padding: 0;
  }
  .media_check_group {
    margin: 0;
    display: flex;
    flex-direction: column;

    .check_box.ant-checkbox-wrapper {
      height: 45px;
      line-height: 45px;
      margin: 0px;
      padding: 0 0 0 5px;
      &.ant-checkbox-wrapper-checked {
        color: #40a9ff;
      }
    }
  }
}
</style>
